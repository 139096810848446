<template>
	<v-container fluid class="px-10">
		<v-row>
			<Actions/>

			<v-col cols="12" class="pa-0 pb-1">
				<v-tabs class="rounded-t-lg" background-color="#192335" color="#fff" align-with-title grow>
					<v-tabs-slider color="primary"></v-tabs-slider>
					<v-tab v-for="d in data" :key="d.name" :to="'/streamerpanel/' + d.route">
						{{ d.name }}
					</v-tab>
				</v-tabs>
			</v-col>

			<v-col class="px-0 py-1 rounded-b-lg">
				<router-view></router-view>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Actions from './Actions/Actions.vue';

export default {
	components: {
		Actions,
	},

	data() {
		return {
			data: [
				{
					name: 'Rewards',
					route: 'rewards'
				},
				{
					name: 'Users',
					route: 'users'
				},
				{
					name: 'Commands',
					route: 'commands'
				},
				{
					name: 'Interval Messages',
					route: 'intervalmessages'
				},
				{
					name: 'Earn Rates',
					route: 'earnrates'
				},
				{
					name: 'Categories',
					route: 'categories'
				},
				{
					name: 'Stream Info',
					route: 'streaminfo'
				},
				{
					name: 'Dashboard Views',
					route: 'dashboardviews'
				},
			]
		}
	},
}
</script>