<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch px-0 py-4">
		<v-row>
			<v-col cols="12" xl="2" lg="2" md="5" sm="6">
				<v-dialog v-model="dialogReset" max-width="400px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn block v-bind="attrs" v-on="on" color="error" outlined>
							<v-icon left>mdi-alert</v-icon>
							Reset all points
						</v-btn>
					</template>

					<v-card>
						<v-card-title>
							<v-icon left>mdi-alert</v-icon>
							Reset all points
						</v-card-title>

						<v-card-text>
							Are you sure you want to reset all users points?
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="secondary" @click="dialogReset = false">Cancel</v-btn>
							<v-btn color="error" :loading="loadingResetBtn" :disabled="loadingResetBtn" @click="ResetAllPoints()">Reset</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>

			<!-- CORE TEAM FUNCTIONALITY -->
			<v-col cols="12" xl="2" lg="2" md="5" sm="6">
				<v-dialog v-model="dialogReset2" max-width="450px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn block v-bind="attrs" v-on="on" color="warning" outlined>
							<v-icon left>mdi-alert</v-icon>
							Reset channel points
						</v-btn>
					</template>

					<v-card>
						<v-card-title>
							<v-icon left>mdi-alert</v-icon>
							Reset your channel points
						</v-card-title>

						<v-card-text>
							Are you sure you want to reset all your channel points?
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="secondary" @click="dialogReset2 = false">Cancel</v-btn>
							<v-btn color="error" :loading="loadingResetBtn2" :disabled="loadingResetBtn2" @click="ResetChannelPoints()">Reset</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
			<!-- CORE TEAM FUNCTIONALITY -->

			<!-- CORE TEAM FUNCTIONALITY -->
			<v-col cols="12" xl="2" lg="2" md="5" sm="6">
				<v-dialog v-model="dialogManageTeam" width="1200px" height="600px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn block v-bind="attrs" v-on="on" color="blue lighten-2" outlined>
							<v-icon left>mdi-account-group</v-icon>
							Manage Team
						</v-btn>
					</template>

					<v-card>
						<v-card-title>
							<v-icon left>mdi-account-group</v-icon>
							Manage Team
						</v-card-title>

						<v-card-text>
							
						</v-card-text>

						<v-card-actions>
							
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
			<!-- CORE TEAM FUNCTIONALITY -->
			
			<!-- CORE TEAM FUNCTIONALITY -->
			<v-col cols="12" xl="2" lg="2" md="5" sm="6">
				<v-dialog v-model="dialogSystem" width="1400px" height="600px" persistent>
					<template v-slot:activator="{ on, attrs }">
						<v-btn block v-bind="attrs" v-on="on" color="blue lighten-2" outlined v-on:click="intervalSystemInfo">
							<v-icon left>mdi-application-cog-outline</v-icon>
							Manage System
						</v-btn>
					</template>

					<v-card flat tile>
						<v-card-title>
							<v-icon left>mdi-application-cog-outline</v-icon>
							<h3>Manage System - PM2 Daemon</h3>
							<v-progress-circular v-if="processesLoading" indeterminate color="primary"></v-progress-circular>

							<v-spacer></v-spacer>

							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon dark @click="clearProcsInterval" v-bind="attrs" v-on="on">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Close</span>
							</v-tooltip>
						</v-card-title>

						<v-card-text>
							<v-simple-table class="pa-2">
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">PM2 ID</th>
											<th class="text-left">PID</th>
											<th class="text-left">Name</th>
											<th class="text-left">Version</th>
											<th class="text-left">NodeJS version</th>
											<th class="text-left">CPU Usage</th>
											<th class="text-left">Memory</th>
											<th class="text-left">Status</th>
											<th class="text-left">Actions</th>
										</tr>
									</thead>
									
									<tbody>
										<Process v-for="proc in procs" :key="proc.pm_id" :proc="proc"/>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>

						<v-card-actions>
							
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>

			<v-col cols="12" xl="2" lg="2" md="5" sm="6">
				<v-dialog v-model="dialogLogs" width="1800px" height="600px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn block v-bind="attrs" v-on="on" color="green lighten-2" outlined v-on:click="getSystemLogs">
							<v-icon left>mdi-list-box-outline</v-icon>
							Logs
						</v-btn>
					</template>

					<v-card flat tile>
						<v-card-title>
							<v-icon left>mdi-list-box-outline</v-icon>
							<h3>Logs</h3>

							<v-spacer></v-spacer>

							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon dark v-bind="attrs" v-on="on">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Close</span>
							</v-tooltip>
						</v-card-title>

						<v-card-text>
							<Logs :data="logs"/>
						</v-card-text>
					</v-card>
				</v-dialog>
			</v-col>
			<!-- CORE TEAM FUNCTIONALITY -->

		</v-row>
	</v-col>
</template>

<script>
import Process from "./Process.vue";
import Logs from "./Logs.vue";

export default {
	components: {
		Process,
		Logs
	},

	data() {
		return {
			e1: 1,
			loadingNewBtn: false,
			loadingResetBtn: false,
			loadingResetBtn2: false,
			loadingNewStreamerBtn: false,
			dialogNewStreamer: false,
			dialogReset: false,
			dialogReset2: false,
			dialogManageTeam: false,
			dialogSystem: false,
			dialogLogs: false,
			procs: [],
			logs: [],
			interval_procs: undefined,
			processesLoading: false,
			logsLoading: false,
		}
	},

	methods: {
		clearProcsInterval () {
			clearInterval(this.interval_procs);
			this.dialogSystem = false;
		},

		async intervalSystemInfo() {
			await this.getSystemInfo();

			this.interval_procs = setInterval(async () => {
				await this.getSystemInfo();
			}, 2000);
		},

		async getSystemInfo() {
			this.processesLoading = true;

			try {
				const request = await fetch(this.$store.state.base_url + '/system/', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {	
					this.procs = res.data;
				}
				else if (request.status === 401) {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.clearProcsInterval();
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}		
				}
			}
			finally {
				this.processesLoading = false;
			}
		},

		async getSystemLogs() {
			this.logsLoading = true;

			try {
				const request = await fetch(this.$store.state.base_url + '/logs', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {	
					this.logs = res.data;
				}
				else if (request.status === 401) {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}		
				}
			}
			finally {
				this.logsLoading = false;
			}
		},

		async AddStreamer() {
			try {
				this.loadingNewStreamerBtn = true;

				const request = await fetch(this.$store.state.base_url + '/actions/streamer/new', {
					method: 'POST',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {	
					if (res.status === 'ok') {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'New streamer enrolled!'
						});
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else if (request.status === 401) {
					res.forEach((msg) => {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: msg.error
						});
					});
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.error
				});
			}
			finally {
				this.loadingNewStreamerBtn = false;
			}
		},

		async ResetAllPoints() {
			try {
				this.loadingResetBtn = true;

				const request = await fetch(this.$store.state.base_url + '/actions/reset', {
					method: 'POST',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {	
					if (res.status === 'ok') {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'All user\'s points resetted successfully!'
						});
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else if (request.status === 401) {
					res.forEach((msg) => {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: msg
						});
					});
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error
				});
			}
			finally {
				this.loadingResetBtn = false;
				this.dialogReset = false;
			}
		},

		async ResetChannelPoints() {
			try {
				this.loadingResetBtn2 = true;

				const request = await fetch(this.$store.state.base_url + '/actions/reset/channel', {
					method: 'POST',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {	
					if (res.status === 'ok') {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'All channel\'s points resetted successfully!'
						});
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else if (request.status === 401) {
					res.forEach((msg) => {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: msg
						});
					});
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error
				});
			}
			finally {
				this.loadingResetBtn2 = false;
				this.dialogReset2 = false;
			}
		}
	},
}
</script>